.categorie-header {
    background-color: hsl(0, 0%, 100%);
    padding: 0px 5px 5px 0px;
    border-right: 2px solid #ddd;
    display: flex;
    flex-direction: column;
    width: 209px;
    min-height: 100vh;
}

.categorie-header>a {
    text-decoration: none;
    color: #5C5C5C;
}

.categorie-subtitle {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    margin: 10px
}

.catagery .active {
    padding-right: 25px;
    padding-left: 20px;
    color: #1b7d78;
    background-color: #1b7d78;
}

.active-link {
    color: #1b7d78;
}

.active {
    color: #1b7d78;
}

.categorie-subtitle.active {
    color: #1b7d78;
    /* border-bottom: 5px solid #1b7d78; */
    /* padding: 18px 0px; */
}

.custom-submit-button {
    background-color: #1b7d78;
    color: #ffffff;
}

.image {
    max-width: 150px;
    padding: 10px;

}

.upload-icon {
    width: 45px;
    height: 50px;
    padding-top: 5px;
}

.cat-form-field {
    display: flex;
    flex-direction: column;
}

.image-field {
    height: 200px;
    width: 200px;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-field.active {
    height: 200px;
    width: 200px;
    border: 1px solid var(--bs-danger-text, #ff0000);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-icon {
    text-align: center;
}

.textfile {
    text-align: center;
    /* padding-top: 20px; */
}

.justify-content-between {
    justify-content: space-between !important;
}

.table-responsive header {
    display: none;
}

.marginTop {
    margin-top: 35px
}

/* day2 css styles */
.cat-searh {
    margin-bottom: 35px;
    height: 37px;
    top: 220px;
    left: 106px;
    border-radius: 4px;
    border: 1px;

}

.cat-button {

    border-radius: 4px;
    display: flex;
    gap: 10px;
    height: Hug (39px);
    justify-content: end;
    margin: 14px 10px 10px;
    width: Hug (105px);

}

.cat-button div {
    color: white;
    background-color: #1b7d78;

}

.sub-cat {
    width: 70%;
    margin-left: 170px;
    padding-left: 10px;
    padding-right: 8px;
}

.product-search {
    width: 70%;
    margin-right: 270px;
    margin-left: 100px;
    padding-left: 10px;
    padding-right: 8px;
}

.sub-filter {
    width: 25%;
    padding-left: 10px;
    padding-right: 8px;
    position: relative;
    z-index: 2;
    padding: 0px;
    padding-left: 15px;
}

.product-filter {
    width: 70%;
    padding-left: 10px;
}


.product-filter,
.product-search {

    position: relative;
    z-index: 2;
}

.flex {
    display: flex;
}

.space-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.input-with-icon {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    width: 25%;
}

.inventory-filters {
    border: 1px solid #ccc;
    width: 25%;
    font-family: 'Inter';
    height: calc(100vh - 76px);
    overflow-y: auto;
}

.p-10 {
    padding: 10px
}

.filter-by {
    font-size: 12px;
    font-weight: 400;
    background-color: #F0F2F8;
    color: #5C5C5C;
    line-height: 14.2px;
}

.high-demand {
    font-size: 14px;
    font-weight: 500;
    color: #B8ACAC;
}

.f_10_green {
    font-size: 10px;
    font-weight: 500;
    color: #1B7D78;
}

.f_16_green {
    font-size: 16px;
    font-weight: 500;
    color: #1B7D78;
}

.f_16_5c5c5c {
    font-size: 16px;
    font-weight: 500;
    color: #5C5C5C;
}

.f_12_5c5c5c {
    font-size: 12px;
    font-weight: 500;
    color: #5C5C5C;
}

.f_12_B8ACAC {
    font-size: 12px;
    font-weight: 500;
    color: #B8ACAC;
}

.new {
    background-color: #FBB528;
    width: 36px;
    height: 17px;
    border-radius: 3px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.all-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    background: #1B7D7833;
}

.items-count {
    color: #B8ACAC;
    font-size: 14px;
    font-weight: 500;
}

.category-head {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 15px;
    border-bottom: 1px solid #C8C8C8;
    cursor: pointer;
}

.category-head.active {
    background-color: #1B7D7833;
}

.category-head:hover {
    background-color: #1B7D7833;
}

.subcategory-head.active {
    background-color: #F0F2F8;
}

.subcategory-head:hover {
    background-color: #F0F2F8;

}

.subcategory-list {
    padding: 0px;
}

.subcategory-head {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
    border-bottom: 1px solid #C8C8C8;
    cursor: pointer;
}

.datatable-title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    color: #5C5C5C;
}

.input-control {
    border: none;
    outline: none;
    width: 100%;
}

.input-subcat-with-icon {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    width: 25%;
    margin-right: 20px;
    margin-left: 15px;
}

.button-pro {
    display: flex;
    justify-content: end;
}

.input-cat-with-icon {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    width: 90%;
    flex: 1;
}

.search-box {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    flex: 1;
}

.banner-form {
    width: 45%;
}

.banner-image {
    display: flex;
    justify-content: center;
}

.button-pro {
    display: flex;
    justify-content: end;
}

.child-sidenav {
    display: flex;
}

.childnav-content {
    width: 100%;
    padding-left: 20px;
    height: calc(100vh - 50px);
    overflow-x: auto;
}

.childnav-content::-webkit-scrollbar {
    display: none;
}

.replace-file {
    display: flex;
}

.resolution-desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    font-family: sans-serif;
    margin: 0px 20px;
}

.replace-btn {
    border-radius: 20px;
    border: 1px solid #1b7d78;
    padding: 9px 11px;
    color: #1b7d78;
    margin-top: 20px;
}

.img-container {
    width: 118px;
    height: 118px;
    border-radius: 10px;
    background-color: #1b7d7833;
    margin-right: 20px;
    min-width: 118px;
}

.child-title {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    width: 200px;
}

/* .childnav-name {
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
} */

.charges-form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.charges-form .row{
    flex: 1;
}